import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ htmlClass, lang, meta, title, description, shareGraphicURL }) {
  const data = useStaticQuery(graphql`
    {
      defaultShare: file(relativePath: { eq: "GHM-share.png" }) {
        publicURL
      }
      favicon: file(relativePath: { eq: "favicon.svg" }) {
        publicURL
      }
      faviconFlat: file(relativePath: { eq: "favicon-flat.svg" }) {
        publicURL
      }
    }
  `)

  const { defaultShare, favicon, faviconFlat } = data

  const siteTitle = 'Gracious Hospitality Management'
  const metaTitle = title || siteTitle
  const metaDescription = description || ''
  const shareGraphic = shareGraphicURL || defaultShare.publicURL
  const twitterHandle = ''

  return (
    <Helmet
      htmlAttributes={htmlClass ? { lang, class: htmlClass } : { lang }}
      as
      title={metaTitle}
      titleTemplate={!title ? '%s' : `%s – ${siteTitle}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: shareGraphic,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: twitterHandle,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: shareGraphic,
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon.publicURL} />
      <link rel="mask-icon" href={faviconFlat.publicURL} color="#db8b28" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  shareGraphicURL: PropTypes.string,
}

export default SEO
